/* ImageWithDescription.css */

.image-with-description {
    display: flex;
    align-items: center;
    margin: 20px;
    padding: 10px;
    margin-bottom: 50px;
  }
  
  .image-first .image {
    order: 2;
    margin: 5%;
  }
  
  .text-first .description {
    order: 2;
  }

  .text-first .image {
    margin: 5%;
  }
  
  .image {
    flex: 1;
  }
  
  .image img {
    max-width: 100%; /* Ensure the image doesn't exceed its container */
    height: auto; /* Maintain image aspect ratio */
  }
  
  .description {
    flex: 1;
    margin-left: 20px;
  }

  