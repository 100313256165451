/* Style for the overall container */
.reviews-container {
    display: grid;
    padding: 2%;
    justify-content: space-evenly;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
  }
  
  /* Style for each review item */
  .review {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 15px;
    font-weight: bold;
    display: flex;
    flex-direction: column;
  }
  
  /* Style for the profile icon */
  .profile-icon {
    width: 50px;
    height: 50px;
    background-color: #3498db;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  /* Style for the "Show More" button */
  .show-more-button {
    margin: 5%;
    background-color: #3498db;
    color: #fff;
    padding: 10px;
    margin-top: 20px;
    border: none;
    cursor: pointer;
  }
  
  /* Media query for smaller screens (smartphones) */
  @media screen and (max-width: 767px) {
    .reviews-container {
      grid-template-columns: 1fr;
      padding: 5%;
    }
  }

  .star-rating {
    display: flex;
    font-size: 24px;
  }
  
  .star {
    color: #ffcf00; /* Star color */
  }
  
  .filled-star {
    color: #ffcf00; /* Filled star color */
  }
  
  .half-star {
    color: #ffcf00; /* Half star color */
  }
  
  .empty-star {
    color: #ccc; /* Empty star color */
  }
  