/* Contact.css */

.contact-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bookMessage{
  color:#000;
  font-size:  "1000px";
  font-weight: "bold"l;
  align-content: "center";
  margin-bottom: "40px";
}

.top-section {
  height: 30vh;
  width: 100%;
  opacity: 0.9;
  background-image: url('http://m.gettywallpapers.com/wp-content/uploads/2021/11/Tennis-Wallpapers-1536x864.jpg');
  background-size: cover;
  background-position: center;
}

.contact-info, .social-links {
  display: flex;
  flex-wrap: wrap;
  font-weight: bold;
  justify-content: center;
  font-size: 30px;
  margin-bottom: 30px; /* Increased margin */
}

.contact-item, .social-links a {
  display: flex;
  color: rgb(70, 70, 70);
  align-items: center;
  margin-right: 30px; /* Increased margin */
  cursor: pointer;
  transition: transform 0.2s, font-size 0.2s;
}

.contact-item:hover, .social-links a:hover {
  transform: scale(1.05);
  font-size: 1.1em;
}

.contact-item i {
  color: rgb(76, 76, 76);
  margin-right: 15px; /* Increased margin */
}

/* Added styles to underline phone number and email address */
.contact-item span, .social-links a span {
  text-decoration: underline;
}


.social-links a {
  color: #ffffff;
  font-size: 1.5em; /* Increased font size */
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #525252;
}


@media (max-width: 600px) {
  .contact-info, .social-links {
    font-size: 18px; /* Adjust the font size for smaller screens */
  }

  .contact-item, .social-links a {
    margin-right: 15px; /* Adjust the margin for smaller screens */
  }
}
