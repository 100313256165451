/* ShareIcons.css */

.share-icons-container {
    background-color: rgb(255, 177, 94);
    padding: 40px;
    margin: 20px;
    width: 90%;
    color: white;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: inline-block;
  }
  
  .share-label {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 30px;
  }
  
  .share-icons button {
    color: white;
    background: none;
    border: none;
    padding: 0;
    margin: 0 5px;
  }

  .share-icons button:hover .svg-inline--fa {
    transform: scale(1.2); /* Adjust the scale factor as needed */
  }
  
  .share-icons button:focus {
    outline: none;
  }
  
  .share-icons button:hover {
    cursor: pointer;
  }
  