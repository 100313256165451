/* Navbar.css */
.navbar {
  background-color: #ffffff;
  color: rgb(255, 255, 255);
  padding: 15px;
}

.navbar-container {
  display: flex;
  background-color: white;
  justify-content: space-between;
  align-items: center;
}

.navbar-logo a {
  color: rgb(0, 0, 0);
  text-decoration: none;
  font-size: 24px;
}

.navbar-links {
  font-family: Arial;
  display: flex;
  margin-left: 25%;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
}

.navbar-links a {
  color: rgb(32, 32, 32);
  text-decoration: none;
  margin: 0 40px;
  padding: 10px;
  border-radius: 10px;
  font-size: 16px; /* Adjust the font size to fit the "About Me" link on one line */
  transition: transform 0.2s; /* Added transition for hover effect */

}

.navbar-links a:hover {
  background-color: rgb(204, 204, 204);
  padding: 10px;
  border-radius: 10px;

}

.navbar-toggle {
  display: block;
  cursor:pointer;
  position: absolute;
  right: 30px;
  top:40px
}

@media (max-width: 768px) {
  .navbar-links {
    z-index: 1000;
    display: none;
    flex-direction: column;
    position: absolute;
    top: 110px;
    font-size: large;
    right: 0;
    width: 100%;
    background: #ffffff;
    padding: 10px;
    line-height: 3; /* Adjust line height to increase vertical spacing */

  }

  .navbar-links.active {
    display: flex;
  }

  .navbar-toggle {
    display: block;
    cursor: pointer;
  }

  .navbar-toggle:hover {
    transform: scale(1.2); /* Enlarge the toggler on hover */
  }

  .bar1 {
    width: 25px;
    height: 3px;
    background-color: rgb(0, 0, 0);
    margin: 4px 0;
    transition: none;
  }

  .bar2 {
    width: 25px;
    height: 3px;
    background-color: rgb(0, 0, 0);
    margin: 4px 0;
    transition: none;
  }

  .bar3 {
    width: 25px;
    height: 3px;
    background-color: rgb(0, 0, 0);
    margin: 4px 0;
    transition: none;
  }

  

  .change.bar1.change {
    transform: rotate(-45deg) translate(-4px, 5px);
  }
  
  .change.bar2.change {
    transform: scale(0);
  }
  
  .change.bar3.change {
    transform: rotate(45deg) translate(-4px, -5px);
  }
  
}
