/* VideoComponent.css */
.video-container {
  width: 100%;
  height: 60vh;
  overflow: hidden;
}

.video-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .video-container {
    height: 30vh; /* Adjust the height for smaller screens */
  }
}
