/* Footer.css */

.footer {
  background-color: #eb6512;
  color: white;
  padding: 20px;
  text-align: center;
}

.social-links a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}

.social-links a:hover {
  text-decoration: none;
}



.footer-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align links to the left */
  margin-top: 10px; /* Add some top margin */
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}

.footer-links a {
  color: white;
  text-decoration: none;
  margin-bottom: 5px; /* Add some spacing between links */
  padding-left: 10px; /* Add left padding for bullets */
}

.social-link {
  color: white; /* Set the icon color to white */
  transition: transform 0.2s; /* Add transition for scale */
}

.social-link:hover {
  transform: scale(0.8); /* Scale down on hover (adjust the scale factor as needed) */
}

.footer-links a:before {
  content: "\2022"; /* Bullet character */
  margin-right: 5px;
}
