/* About.css */

.about-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .top-section2 {
    height: 30vh;
    width: 100%;
    opacity: 0.9;
    background-image: url('/Users/oscaralberigo/Desktop/LeccionesTennisMarbella/tennislessonsmarbella/src/court.png');
    background-size: cover;
    background-position: center;
  }

  /* Default styles for larger screens */
.top-section3 {
  height: 30vh;
  width: 28%;
  background-size: cover;
  background-position: center;
  opacity: 0.9;
  background-image: url('/Users/oscaralberigo/Desktop/LeccionesTennisMarbella/tennislessonsmarbella/src/me.jpeg');

}

/* Adjust styles for smaller screens (e.g., mobile) */
@media (max-width: 767px) {
  .top-section3 {
    width: 100%;
    background-size: cover;
    background-position: center;
    opacity: 0.9;
    background-image: url('/Users/oscaralberigo/Desktop/LeccionesTennisMarbella/tennislessonsmarbella/src/me.jpeg');
  }
}

  .me{
    margin-top: 5%;
    width: 20%;
    height: auto;
  }
  
  .about-title, .about-text {
    opacity: 0;
    margin: 15px; /* Initially set to be invisible */
  }
  
  /* Slide in effect for about-title */
  .about-title {
    animation: slideInTop 1s forwards;
  }
  
  /* Slide in effect for about-text */
  .about-text {
    animation: slideInBottom 1s forwards;
    margin-bottom: 30px; /* Add margin below the text */
    padding-left: 30px;
    padding-right: 30px;
    white-space: pre-line;  
    vertical-align: bottom;
  }
  
  @keyframes slideInTop {
    from {
      transform: translateY(-50%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes slideInBottom {
    from {
      transform: translateY(50%);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  /* Update this in your CSS file */
#scroll-to-top-button {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 50%; /* Center the button horizontally */
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#scroll-to-top-button:hover {
  background-color: #555;
}
  