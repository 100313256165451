/* HomeScreen.css */
.home-screen {
  position: relative;
  height: 45vh;
  padding: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Hide overflowing content */
}

#video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content {
  position: relative;
  z-index: 1;
  padding: 20px;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  animation: fadeIn 1s forwards; /* Fade in effect for the content */
}

.text-center {
  text-align: center;
  font-family: Arial;
  font-weight:100;
  font-size: 30px;
  color: rgb(255, 255, 255);
  text-shadow: 4px 4px 4px rgba(0, 0, 0, 0.5);
  animation: slideInTop 1s forwards; /* Slide in from the top for the h1 tag */
  line-height: 1.5; /* Adjust line height to increase vertical spacing */
}

.custom-button {
  font-family: Arial;
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 40px;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: slideInBottom 1s forwards; /* Slide in from the bottom for the button */
}

.custom-button2 {
  font-family: Arial;
  background-color: rgb(54, 54, 54);
  border: 2px solid rgb(73, 73, 73);
  color: rgb(255, 255, 255);
  padding: 10px 20px;
  font-size: 18px;
  border-radius: 40px;
  margin-bottom: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: slideInBottom 1s forwards; /* Slide in from the bottom for the button */
}

.custom-button:hover {
  background-color: rgb(0, 0, 0);
  border: 2px solid rgb(0, 0, 0);
  color: rgb(255, 255, 255);
}

.custom-button2:hover {
  background-color: rgb(255, 255, 255);
  border: 2px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes slideInTop {
  from {
      transform: translateY(-50%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

@keyframes slideInBottom {
  from {
      transform: translateY(50%);
      opacity: 0;
  }
  to {
      transform: translateY(0);
      opacity: 1;
  }
}

/* Update this in your CSS file */
#scroll-to-top-button {
  display: none;
  position: fixed;
  bottom: 20px;
  left: 50%; /* Center the button horizontally */
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

#scroll-to-top-button:hover {
  background-color: #555;
}

