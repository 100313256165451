/* CardComponent.css */

.card {
    display: flex;
    flex-wrap: wrap;
    margin-top: 50px;
    margin-bottom: 20px;
    margin-right: 20px;
    margin-left: 10px;
    width: 98%; /* Adjust the card width as needed */
    padding: 10px;
    background-color: #ffffff; /* Adjust the background color as needed */
    border: none; /* Adjust the border properties as needed*/
}


.text-box-container {
    display: flex;
    flex-wrap: wrap; 
    width: 100%;
}

.text-box:hover {
    transform: scale(1.05); /* Scale up the card on hover */
}
  
.text-box {
    width: 48%; /* Adjust the width of the text boxes to fit the grid */
    margin: 1%;
    padding: 10px;
    background-color: rgba(255, 126, 21, 0.895); /* Adjust the background color of the text boxes */
    border: none; /* Adjust the border properties of the text boxes */
    transition: transform 0.3s;
    color: white;
}
  
@media (max-width: 600px) {
    .text-box {
        width: 100%; /* For smaller screens, make text boxes occupy full width */
    }
}
