/* Profile.css */
.profile-container {
    display: flex;
    align-items: flex-start;
    background-color: #ffffff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .profile-image {
  }

  .superprof-link {
    display: inline-block;
    overflow: hidden;
  }
  
  .superprof-logo {
    transition: transform 0.3s ease-in-out;
  }
  
  .superprof-link:hover .superprof-logo {
    transform: scale(1.1);
  }
  
  .profile-image img {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .profile-info {
    text-align: left;
  }
  
  .profile-name {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .profile-description {
    font-size: 16px;
    color: #555;
  }
  