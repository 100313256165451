/* Blog.css */

.blog-header {
    font-size: 28px;
    color: #333;
    margin: 10px;
    margin-bottom: 20px;
  }
  
  .blog-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
  }
  
  .blog-post {
    cursor: pointer;
    margin: 10px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 90%;
  }
  
  .blog-title {
    color: #333;
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .blog-snippet {
    color: #666;
    font-size: 16px;
  }
  
  .detailed-view {
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .back-button {
    cursor: pointer;
    font-size: 16px;
    background-color: #0066cc;
    color: #fff;
    border: none;
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 20px;
  }
  
  .detailed-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
  }
  
  .section-heading {
    color: #0066cc;
    font-size: 20px;
  }
  
  .section-content {
    color: #666;
    font-size: 16px;
  }

  .detailed-image {
    width: 30%;
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 5px;
  }

  @media (max-width: 768px) {
    .detailed-image {
      width: 100%;
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 5px;
    }
  }

  